import { useLocation } from 'react-router-dom';
import { Navigate, Route, Routes, useRoutes } from "react-router";

// routes
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = ({ LoginData }) => {
    const location = useLocation();
    const background = location.state && location.state.background;
    const mainRoute = useRoutes([MainRoutes]);
    const authRoute = useRoutes([AuthRoutes]);

    if (LoginData) {
        return mainRoute;
    }
    return authRoute
}

export default ThemeRoutes;
