import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const ProfileLayout = Loadable(lazy(() => import('views/account/profile/ProfileLayout')));
const ProfileDetail = Loadable(lazy(() => import('views/account/profile/ProfileDetail')));

const SettingsLayout = Loadable(lazy(() => import('views/settings/SettingLayout')));
const ContactDetails = Loadable(lazy(() => import('views/settings/ContactDetails')));
const ChangePassword = Loadable(lazy(() => import('views/settings/ChangePassword')));
const TermsConditions = Loadable(lazy(() => import('views/settings/TermsConditions')));

const UserList = Loadable(lazy(() => import('views/users')));
const BannerManagement = Loadable(lazy(() => import('views/bannerManagement')));
const GiftCardManagement = Loadable(lazy(() => import('views/giftCardManagement')));
const OfferManagement = Loadable(lazy(() => import('views/offerManagement')));
const ReceiptManagement = Loadable(lazy(() => import('views/receiptManagement')));
const ReferralManagement = Loadable(lazy(() => import('views/referralManagement')));
const RulesManagement = Loadable(lazy(() => import('views/rulesManagement')));
const TransactionManagement = Loadable(lazy(() => import('views/transactionManagement')));
const Scheduled = Loadable(lazy(() => import('views/notificationManagement/scheduled')));
const Templates = Loadable(lazy(() => import('views/notificationManagement/templates')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'users',
            element: <UserList />
        },
        {
            path: 'bannerManagement',
            element: <BannerManagement />
        },
        {
            path: 'giftCardManagement',
            element: <GiftCardManagement />
        },
        {
            path: 'offerManagement',
            element: <OfferManagement />
        },
        {
            path: 'receiptManagement',
            element: <ReceiptManagement />
        },
        {
            path: 'referralManagement',
            element: <ReferralManagement />
        },
        {
            path: 'rulesManagement',
            element: <RulesManagement />
        },
        {
            path: 'transactionManagement',
            element: <TransactionManagement />
        },
        {
            path: 'notificationManagement',
            children: [
                {
                    path: 'scheduled',
                    element: <Scheduled />
                },
                {
                    path: 'template',
                    element: <Templates />
                }
            ]
        },
        {
            path: 'settings',
            element: <SettingsLayout />,
            children: [
                {
                    path: 'contact-details',
                    element: <ContactDetails />
                },
                {
                    path: 'change-password',
                    element: <ChangePassword />
                },
                {
                    path: 'terms-conditions',
                    element: <TermsConditions />
                }
            ]
        },
        {
            path: 'account',
            element: <ProfileLayout />,
            children: [
                {
                    path: 'settings',
                    element: <ProfileDetail />
                },
                {
                    path: 'change-password',
                    element: <ChangePassword />
                }
            ]
        },

    ]
};

export default MainRoutes;
