import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/account/authentication3/Login')));
const AuthRegister = Loadable(lazy(() => import('views/account/authentication3/Register')));
const AuthForgetPassword = Loadable(lazy(() => import('views/account/authentication3/ForgetPassword')));
const AuthForgetOtp = Loadable(lazy(() => import('views/account/authentication3/ForgetOtp')));
const AuthResetPassword = Loadable(lazy(() => import('views/account/authentication3/ResetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/account/login',
            element: <AuthLogin />
        },
        {
            path: '/account/register',
            element: <AuthRegister />
        },
        {
            path: '/account/forgetPassword',
            element: <AuthForgetPassword />
        },
        {
            path: '/account/forgetOtp',
            element: <AuthForgetOtp />
        },
        {
            path: '/account/resetPassword',
            element: <AuthResetPassword />
        }
    ]
};

export default AuthenticationRoutes;
