import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCard = forwardRef(({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, closeModal, ...others }, ref) => {
    const theme = useTheme();

    return (
        <Card
            ref={ref}
            sx={{
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                ':hover': {
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                },
                ...sx
            }}
            {...others}
        >
            {/* card header and action */}
            {title && (
                <CardHeader
                    sx={{ p: 2 }}
                    title={<Typography variant="h3">{title}</Typography>}
                    action={
                        closeModal && (
                            <IconButton onClick={closeModal}>
                                <CloseRoundedIcon />
                            </IconButton>
                        )
                    }
                ></CardHeader>
            )}

            {/* content & header divider */}
            {title && (
                <Divider
                    sx={{
                        opacity: 1,
                        borderColor: theme.palette.primary.light
                    }}
                />
            )}

            {/* card content */}
            {content && (
                <CardContent
                    sx={{ overflowY: 'auto', p: 1.5, height: secondary ? `calc(100vh - 125px)` : `calc(90vh)`, ...contentSX }}
                    className={contentClass || ''}
                >
                    {children}
                </CardContent>
            )}
            {!content && children}
            {secondary && (
                <>
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: theme.palette.primary.light
                        }}
                    />
                    <CardActions
                        sx={{
                            padding: '0px !important',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <CardHeader sx={{ p: 2.1 }} action={secondary} />
                    </CardActions>
                </>
            )}
        </Card>
    );
});

SubCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    closeModal: PropTypes.func
};

SubCard.defaultProps = {
    content: true
};

export default SubCard;
