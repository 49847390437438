import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  jcL: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  smallTextField: {
    "& .MuiInput-root": {
      maxHeight: "30px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
}));

export default function CustomInput(props) {
  const { icon, textSmall, ...other } = props;
  const classes = useStyles();

  return (
    <Box className={classes.jcL}>
      <TextField
        {...other}
        classes={textSmall ? { root: classes.smallTextField } : null}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={icon && { marginLeft: 10, marginRight: 10 }}
            >
              {icon === "search" && <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
