class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.AuthToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.authToken;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.AuthToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
  getToken() {
    return JSON.parse(localStorage.getItem("authToken"));
  }
  setToken(authToken) {
    localStorage.setItem("authToken", JSON.stringify(authToken));
  }
  removeToken() {
    localStorage.removeItem("authToken");
  }
}
export default new TokenService();