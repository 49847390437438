import {
    IconDashboard,
    IconUsers,
    IconKey,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconBrandChrome,
    IconNotification
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    //title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: IconDashboard,
            breadcrumbs: false
        },

        {
            id: 'User Management',
            title: 'User Management',
            type: 'item',
            url: '/users',
            icon: IconUsers,
            breadcrumbs: false,
            //external: true,
            //target: true
        },
        {
            id: 'Banner Management',
            title: 'Banner Management',
            type: 'item',
            url: '/bannerManagement',
            icon: IconKey,
            breadcrumbs: false
        },
        {
            id: 'Gift Cards Management',
            title: 'Gift Cards Management',
            type: 'item',
            url: '/giftCardManagement',
            icon: IconTypography,
            breadcrumbs: false
        },
        {
            id: 'Offer Management',
            title: 'Offer Management',
            type: 'item',
            url: '/OfferManagement',
            icon: IconTypography,
            breadcrumbs: false
        },
        {
            id: 'Receipt Management',
            title: 'Receipt Management',
            type: 'item',
            url: '/receiptManagement',
            icon: IconPalette,
            breadcrumbs: false
        },
        {
            id: 'Referral Management',
            title: 'Referral Management',
            type: 'item',
            url: '/referralManagement',
            icon: IconShadow,
            breadcrumbs: false
        },
        {
            id: 'Rules Management',
            title: 'Rules Management',
            type: 'item',
            url: '/rulesManagement',
            icon: IconWindmill,
            breadcrumbs: false
        },
        {
            id: 'Transaction Management',
            title: 'Transaction Management',
            type: 'item',
            url: '/transactionManagement',
            icon: IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'notificationManagement',
            title: 'Notification Management',
            type: 'collapse',
            icon: IconNotification,
            children: [
                {
                    id: 'notification-scheduled',
                    title: 'Schedules',
                    type: 'item',
                    url: '/notificationManagement/scheduled',
                    breadcrumbs: false
                },
                {
                    id: 'notification-templates',
                    title: 'Templates',
                    type: 'item',
                    url: '/notificationManagement/template',
                    breadcrumbs: false
                }
            ]
        },
    ]
};

export default dashboard;
