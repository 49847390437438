import { Stack } from '@mui/system';
import LogoImg from 'assets/images/Kayyel-Logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <Stack flexDirection="row" justifyContent="center">
            <img src={LogoImg} alt="Logo" style={{ height: 50, width: 180 }} />
        </Stack>
    );
};

export default Logo;
