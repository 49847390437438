import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Drawer, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { IconLogout } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { useDispatch, useSelector } from 'react-redux';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'utils/AuthContext';

import { useNavigate } from 'react-router';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const customization = useSelector((state) => state.customization);
    const AuthContextData = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogOut = () => {
        AuthContextData.signOut('');
    }

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView style={{ padding: 15 }}>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        //backgroundColor: theme.palette.primary.main,
                        backgroundImage: 'linear-gradient(to bottom, #53589F, #12043F)',
                        borderRadius: 25,
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',

                    }}
                >
                    <MenuList />


                    <div style={{ marginBottom: 30, marginLeft: 10 }}>
                        <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            onClick={() => navigate('/settings')}
                        >
                            <ListItemIcon sx={{ color: '#fff' }}><IconLogout stroke={1.5} size="1.3rem" /></ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">
                                        Settings and configuration
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                        <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            onClick={() => handleLogOut()}
                        >
                            <ListItemIcon sx={{ color: '#fff' }}><IconLogout stroke={1.5} size="1.3rem" /></ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" >
                                        Logout
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </div>
                    {/* <Divider sx={{my:2}}/>
                    <MenuCard /> */}
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
